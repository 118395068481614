<template>
  <div class="home">
    <!-- header -->
    <el-header style="height: 100%">
      <div class="header-top">
        <div class="header-inner">
          <div class="inner-left gradient-text">
            欢迎进入高考桥官方网站！
            <!-- <el-dropdown @command="htmlTypeCommand">
              <span class="el-dropdown-link" :style="{'color': themeColor}">
                {{htmlType}}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="高考桥">高考桥</el-dropdown-item>
                <el-dropdown-item command="中考桥">中考桥</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </div>
          <div class="inner-right">
            <ul class="btns">
              <li
                class="btn-item"
                v-for="(e, i) in headerBtns"
                :key="i"
                @click="routePush(e)"
              >
                <img v-if="e.icon" :src="e.icon" alt="" />
                <span>{{ e.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 顶部搜索 -->
      <div class="header-logo">
        <router-link to="/">
          <img src="../assets/logo.png" class="logo" />
        </router-link>
        <div class="search-box" :style="{ borderColor: themeColor }">
          <el-dropdown trigger="click" @command="searchDrop">
            <span class="el-dropdown-link" style="cursor: pointer">
              {{ options[opti].label
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :command="item.value"
                v-for="item in options"
                :key="item.value"
                >{{ item.label }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>

          <input
            type="text"
            placeholder="请输入您要搜索的课程"
            @keyup.enter="search"
            v-model.trim="searchForm.text"
          />
          <el-button
            class="submit-button"
            type="primary"
            :style="{
              'background-color': themeColor,
              'border-color': themeColor,
            }"
            @click="search"
            >搜索</el-button
          >
        </div>
      </div>
    </el-header>
    <div class="nav" ref="test" :class="{ fixed: isFixed }">
      <div class="nav-top"></div>
      <div class="nav-box">
        <ul class="tabs">
          <li
            class="tab tab-index0"
            @click="routePush({ link: '/course_index' })"
          >
            全部学科分类
          </li>
          <li
            class="tab"
            v-for="(e, i) in tabs"
            :class="e.isActive ? 'tab-select' : ''"
            :key="i"
            @click="tabClick(e, i)"
          >
            <span v-if="!e.data"> {{ e.name }}</span>
            <!-- <span v-else>{{ e.name }}</span> -->
            <el-dropdown v-else :show-timeout="50" @command="handleCommand">
              <span type="primary">
                {{ e.name }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in e.data"
                  :key="item.id"
                  :command="JSON.stringify(item)"
                  >{{ item.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <img v-if="e.name == '课程中心'" src="../assets/hot.gif" alt=""> -->
          </li>
        </ul>
        <div class="subs"></div>
      </div>
    </div>
    <div>
      <router-view></router-view>
    </div>
    <footer class="foot">
      <div class="foot-inner">
        <div class="foot-top">
          <div class="foot-link-main">
            <p>友情链接：</p>
            <ul>
              <li v-for="(link, index) in links" :key="index">
                <a :href="getAllHref(link.url)" target="_blank">{{
                  link.name
                }}</a>
                <!-- <el-divider direction="vertical" v-if="index < links.length - 1"></el-divider> -->
              </li>
            </ul>
          </div>
          <div class="foot-qrcode">
            <div class="down-app">
              <img :src="getImgSrc('/static/images/app.png')" alt="" />
              <span>下载APP</span>
            </div>
            <div class="telephone">
              <div class="tel"><span>客服电话：</span>400-6030-985</div>
              <div class="time">
                <span>服务时间：</span>08:30~12:00 13:30~17:30(周一至周五)
              </div>
            </div>
          </div>
        </div>
        <div class="foot-bot">
          <div class="bot-left" v-if="sites">
            <p>{{ sites ? sites.copyright.name : "" }}</p>
            <p>
              备案号：{{ sites ? sites.gongan.name : "" }}
              {{ sites ? sites.icp.name : "" }}
            </p>
          </div>
          <div class="bot-right">
            <p>
              <span>违法和不良信息举报电话: 18617814232</span>
              <span>举报邮箱: 2390691438@qq.com</span>
            </p>
            <a href="https://www.12377.cn/" target="_blank"
              >网上有害信息举报入口></a
            >
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "../store";
import CommonUtil from "../utils/common_util";
import http from "../utils/http";
export default {
  name: "HomeView",
  data() {
    return {
      setPhoneNum: CommonUtil.setPhoneNumSecret,
      getAllHref: CommonUtil.getAllHref,
      getImgSrc: CommonUtil.getImgSrc,
      searchForm: {
        course: "1",
        text: "",
      },
      themeColor: "",
      options: [
        {
          value: "1",
          label: "课程",
        },
        // {
        //   value: "2",
        //   label: "知识点",
        // },
      ],
      opti: 0,
      swiperArr: [
        "https://www.gaokao66.com/./uploads/images/20210806/610d3bbddf501.jpg",
        "https://www.gaokao66.com/./uploads/images/20210913/613f108676853.jpg",
        "https://www.gaokao66.com/./uploads/images/20230520/64688aa526756.jpg",
      ],
      tabs: [],
      links: [],
      sites: null,
      htmlType: "高考桥",
      isFixed: false,
    };
  },
  async mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.themeColor = this.$store.state.themeColor;
    store.commit("topBtn");
    await this.getNavBrowse();
    await this.getLinks();
    await this.getSites();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapState(["hasLogin", "userInfo", "headerBtns"]),
  },
  methods: {
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const testEle = this.$refs.test;
      if (scrollTop > testEle.offsetTop) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    searchDrop(command) {
      // let item = JSON.parse(command)
      // console.log(item)
    },
    allCategories() {
      this.$router.push("/allCategories");
    },
    routePush(e) {
      this.$router.push(e.link);
    },
    search() {
      console.log(this.searchForm);
      this.$router.push({
        name: "search",
        params: {
          title: "搜索结果",
          name: this.searchForm.text,
        },
      });
    },
    routerPush(routeAddress) {
      this.$router.push(routeAddress);
    },
    tabClick(e, i) {
      // console.log(e, i)
      e.isActive = true;
      this.tabs.forEach((m, n) => {
        if (n !== i) {
          m.isActive = false;
        }
      });
      if (e.name == "智能练习") return;
      // if (e.name == '志愿填报') {
      //   window.open(this.getAllHref(e.description))
      //   return
      // }
      // console.log(this.tabs)
      // this.routerPush("/" + e.alias)
      this.$router.push({
        name: e.alias,
        params: {
          title: e.name,
        },
      });
    },
    // 首页公共导航
    async getNavBrowse() {
      let { data: res } = await http.post("api/auth/nav_browse");
      // console.log(res)
      if (res.code == 0) {
        let item = {
          data: null,
          id: 100,
          name: "冲刺中考",
          pid: 0,
          alias: "cczk",
          description: "",
          pic_pc: "",
          pic_phone: null,
        };
        // res.data.splice(5,0,item)
        this.tabs = res.data;
        this.tabs.forEach((e, i) => {
          if (i == 0) {
            e.isActive = true;
          } else {
            e.isActive = false;
          }
        });
        let index = this.tabs.findIndex((e) => e.name == "课程中心");
        if (index !== -1) {
          let courseBrowse = res.data[index];
          window.sessionStorage.setItem(
            "courseBrowse",
            JSON.stringify(courseBrowse)
          );
        }
      }
    },
    handleCommand(command) {
      let item = JSON.parse(command);
      console.log(item);
      // this.routerPush("/" + item.alias)
      this.$router.push({
        name: item.alias,
        params: {
          title: item.name,
          obj: command,
        },
      });
    },
    async getLinks() {
      let { data: res } = await http.post("api/auth/links");
      if (res.code == 0) {
        this.links = res.data;
        // console.log(this.links)
      }
    },
    async getSites() {
      let { data: res } = await http.post("api/auth/sites");
      if (res.code == 0) {
        this.sites = res.data;
        store.commit("setGrey", { grey: res.data.grey.name });
        document
          .querySelector('meta[name="keywords"]')
          .setAttribute("content", res.data.keywords.name);
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", res.data.description.name);
        // console.log(this.sites)
      }
    },
    async getSearch() {
      let { data: res } = await http.post("api/course/course_search", {
        name: this.searchForm.text,
      });
      if (res.code == 0) {
        console.log(res);
      }
    },
    htmlTypeCommand(e) {
      this.htmlType = e;
      if (e == "中考桥") {
        window.location.replace("https://junior.gkq211.com");
      }
    },
  },
};
</script>
<style  lang='less' scoped>
.home {
  overflow: hidden;
}

.header-top {
  width: 100vw;
  min-width: 1200px;
  height: 40px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #dddddd;
  font-size: 13px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;

  .header-inner {
    width: 1200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inner-right {
    .btns {
      display: flex;
      align-items: center;

      .btn-item {
        margin-left: 16px;
        cursor: pointer;

        img {
          vertical-align: middle;
          margin-right: 5px;
          margin-top: -2px;
        }
      }
    }
  }
}

.header-logo {
  display: flex;
  width: 1200px;
  height: 105px;
  margin: 0 auto;
  align-items: center;

  .search-box {
    width: 520px;
    height: 45px;
    background-color: #fff;
    // box-sizing: border-box;
    border: 2px solid;
    margin-left: 120px;
    display: flex;

    > div {
      width: 80px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    input {
      width: 384px;
      height: 41px;
      line-height: 41px;
      border: none;
      background-color: #fff;
      box-sizing: border-box;
      padding-left: 20px;
      outline: none;
    }

    .el-button {
      display: inline-block !important;
      border: none !important;
      border-radius: 0 !important;
    }

    .el-select {
      width: 130px !important;
      border-radius: 0 !important;
    }
  }
}

.nav {
  width: 100vw;
  min-width: 1200px;
  position: relative;
  height: 50px;

  .nav-top {
    width: 100vw;
    height: 50px;
    background-color: rgba(0, 144, 240, 1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 40;
  }

  .nav-box {
    width: 1200px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    .tabs {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 70;

      .tab {
        cursor: pointer;
        height: 50px;
        padding: 0 40px;
        line-height: 50px;
        background-color: rgba(0, 144, 240, 1);
        position: relative;

        .el-dropdown {
          color: #fff !important;
          font-size: 16px !important;
          vertical-align: top;
        }

        img {
          width: 21px;
          height: 16px;
          position: absolute;
          top: 6px;
          right: 14px;
        }
      }

      .tab:hover {
        background-color: #2dabff;
      }

      .tab-select {
        background-color: #2dabff;
      }

      .tab-index0 {
        background-color: rgba(0, 144, 240, 0.4);
        width: 230px;
        text-align: center;
      }

      .tab-index0:hover {
        background: rgba(0, 144, 240, 0.4);
      }

      .tab-bgnone {
        background: none;
      }
    }

    .subs {
      width: 260px;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 50;
    }
  }
}

.foot {
  width: 100%;
  min-width: 1200px;
  min-height: 220px;
  background-color: #292929;

  .foot-inner {
    width: 1200px;
    height: 100%;
    box-sizing: border-box;
    padding: 0 40px;
    margin: auto;

    .foot-top {
      width: 100%;
      min-height: 180px;
      // margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .foot-link-main {
        width: 680px;
        // height: 100%;
        box-sizing: border-box;

        > p {
          color: #928f8f;
        }

        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            a {
              display: inline-block;
              margin: 5px;
              color: #928f8f;
              width: 158px;
              line-height: 28px;
              border: 1px solid #757575;
              text-align: center;
            }

            a:hover {
              color: #fff;
              border: 1px solid #fff;
            }
          }
        }
      }

      .foot-qrcode {
        width: 384px;
        height: 100%;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .telephone {
          color: #928f8f;
          width: 240px;

          div {
            line-height: 30px;

            span {
              color: #928f8f;
            }
          }
        }

        .down-app {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 120px;
            height: 120px;
            margin-bottom: 8px;
          }

          span {
            font-size: 13px;
            color: #928f8f;
            height: 13px;
          }
        }
      }
    }

    .foot-bot {
      width: 100%;
      height: 70px;
      border-top: solid 1px #efeff0;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .bot-left {
        width: 40%;
        height: 40px;

        p {
          font-size: 13px;
          color: #928f8f;
          height: 6px;
        }
      }

      .bot-right {
        width: 60%;
        height: 40px;

        p {
          font-size: 14px;
          color: #928f8f;
          height: 6px;

          span {
            margin-right: 10px;
          }
        }

        a {
          font-size: 14px;
          color: #f56c6c;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
</style>